import {
  type GetPagesQuery,
  Locale,
  type CButtonLink,
  type CTextLink,
  type CImageLink,
} from '@seek/cmsu-cms-connect';
import merge from 'deepmerge';

type Blocks = GetPagesQuery['pages'][0]['containers'][0]['blocks'];

type BlockItems = Blocks[0]['items'];

export const removeNullProperties = (
  obj: Record<string, any>,
): Record<string, any> =>
  Object.entries(obj).reduce(
    (acc: Record<string, any>, [key, value]: [string, any]) => {
      if (value !== null) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          acc[key] = removeNullProperties(value);
        } else {
          acc[key] = value;
        }
      }
      return acc;
    },
    {},
  );

export const removeBlocksThatHaveHaveNoItems = (blocks: Blocks): Blocks =>
  blocks.filter((block) => block.items.length !== 0);

export const filterBlockItemsByLocale = (blockItems: BlockItems): BlockItems =>
  keepBlockItemsThatHaveContent(
    filterOutDefaultLocaleContents(filterOutDefaultLocaleItems(blockItems)),
  );

/**
 * The function keeps block items from an array that have content based on their type.
 * @param blockItems
 */
export const keepBlockItemsThatHaveContent = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.filter((item) => {
    if (item.__typename === 'AccordionGroup') {
      return item.accordions.length !== 0;
    }

    if (item.__typename === 'CImageBlockGroup') {
      return item.items.length !== 0;
    }

    if (item.__typename === 'CCardGroup') {
      return item.cards.length !== 0;
    }

    if (item.__typename === 'TabGroup') {
      return item.tabs.length !== 0;
    }

    if (item.__typename === 'CIconBlockGroup') {
      return item.items.length !== 0;
    }

    if (item.__typename === 'CActionGroup') {
      return item.actions.length !== 0;
    }

    if (item.__typename === 'CBanner') {
      if (item.banner_items?.__typename === 'CBannerInline') {
        // Need a disucssion about determining if we need to set an image
        // as mandatory or not
        // return item.banner_items?.image !== undefined;
        return item.banner_items?.heading !== undefined;
      }
    }

    if (item.__typename === 'CBanner') {
      if (item.banner_items?.__typename === 'CBannerArticle') {
        return item.banner_items?.name !== undefined;
      }
    }

    if (item.__typename === 'CCustomComponent') {
      return item.data?.__typename !== undefined;
    }

    if (item.__typename === 'CAlert') {
      return item.paragraph?.Paragraph_text?.raw !== undefined;
    }
    if (item.__typename === 'CGallery') {
      return item.assets.length !== 0;
    }

    return true;
  });

/**
 * The function filters out block items contents that are not default locale.
 * @param blockItems
 */
export const filterOutDefaultLocaleContents = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.map((item) => {
    if (item.__typename === 'AccordionGroup') {
      return {
        ...item,
        accordions: item.accordions.filter((a) => a.locale !== Locale.Default),
      };
    }

    if (item.__typename === 'CCardGroup') {
      return {
        ...item,
        cards: item.cards.filter(
          (card) =>
            card.heading?.locale !== Locale.Default &&
            card.paragraph?.locale !== Locale.Default &&
            card.image?.locale !== Locale.Default,
        ),
      };
    }

    if (item.__typename === 'CGallery') {
      return {
        ...item,
        assets: item.assets.filter((a) => a.locale !== Locale.Default),
      };
    }

    if (item.__typename === 'CImageBlockGroup') {
      return {
        ...item,
        items: item.items.filter(
          (i) =>
            i.image?.locale !== Locale.Default &&
            i.heading?.locale !== Locale.Default &&
            i.paragraph?.locale !== Locale.Default,
        ),
      };
    }

    if (item.__typename === 'TabGroup') {
      return {
        ...item,
        tabs: item.tabs.filter((t) => t.locale !== Locale.Default),
      };
    }

    if (
      item.__typename === 'CBanner' &&
      item.banner_items &&
      item.banner_items.__typename === 'CBannerInline'
    ) {
      return {
        ...item,
        banner_items: {
          ...item.banner_items,
          image:
            item.banner_items?.image?.locale !== Locale.Default
              ? item.banner_items?.image
              : undefined,
          heading:
            item.banner_items?.heading?.locale !== Locale.Default
              ? item.banner_items?.heading
              : undefined,
          actionGroup: item.banner_items?.actionGroup
            ? {
                ...item.banner_items?.actionGroup,
                actions: item.banner_items?.actionGroup.actions.filter(
                  (a) => !isLinkItemWithDefaultLocale(a),
                ),
              }
            : undefined,
          inlineDivider: item.banner_items?.inlineDivider
            ? {
                ...item.banner_items?.inlineDivider,
                actions: item.banner_items?.inlineDivider.actions.filter(
                  (a) => !isLinkItemWithDefaultLocale(a),
                ),
              }
            : undefined,
          paragraph:
            item.banner_items?.paragraph?.locale !== Locale.Default
              ? item.banner_items?.paragraph
              : undefined,
        },
      };
    }
    if (
      item.__typename === 'CBanner' &&
      item.banner_items &&
      item.banner_items.__typename === 'CBannerArticle'
    ) {
      return {
        ...item,
        banner_items: {
          ...item.banner_items,
          name:
            item.banner_items?.locale !== Locale.Default
              ? item.banner_items?.name
              : undefined,
        },
      };
    }

    if (item.__typename === 'CActionGroup') {
      return {
        ...item,
        actions: item.actions.filter((a) => !isLinkItemWithDefaultLocale(a)),
      };
    }

    if (item.__typename === 'CIconBlockGroup') {
      return {
        ...item,
        items: item.items.filter(
          (i) =>
            i.heading?.locale !== Locale.Default &&
            i.paragraph?.locale !== Locale.Default,
        ),
      };
    }

    if (
      item.__typename === 'CCustomComponent' &&
      item.data?.__typename === 'WebForm'
    ) {
      return {
        ...item,
        data:
          item?.data?.locale !== Locale.Default ? { ...item.data } : undefined,
      };
    }

    if (
      item.__typename === 'CCustomComponent' &&
      item.data?.__typename === 'CForm'
    ) {
      return {
        ...item,
        data:
          item?.data?.form?.locale !== Locale.Default
            ? { ...item.data }
            : undefined,
      };
    }

    if (item.__typename === 'CAlert') {
      return {
        ...item,
        paragraph: {
          ...item.paragraph,
          Paragraph_text: {
            raw:
              item.paragraph?.locale !== Locale.Default
                ? item.paragraph?.Paragraph_text?.raw
                : undefined,
          },
        },
      };
    }

    return item;
  });

export const isLinkItemWithDefaultLocale = (item: {
  __typename?: string;
}): boolean => {
  const linkTypes = ['CButtonLink', 'CTextLink', 'CImageLink'];
  return (
    linkTypes.includes(item.__typename ?? '') &&
    (item as CButtonLink | CTextLink | CImageLink).locale === Locale.Default
  );
};

/**
 * Filters an array of block items to return only blocks that do not have default locale.
 * @param blockItems
 */
export const filterOutDefaultLocaleItems = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.filter((item) => {
    if ('locale' in item && item.locale === Locale.Default) {
      return false;
    }

    if (
      item.__typename === 'Video' &&
      item.source &&
      'locale' in item.source &&
      item.source.locale === Locale.Default
    ) {
      return false;
    }

    return true;
  });

export const formatBlocksForRendering = (blocks: Blocks): Blocks =>
  removeBlocksThatHaveHaveNoItems(
    blocks.map((b) => {
      const formattedItems = !b.skipDefaultLocale
        ? b.items
        : filterBlockItemsByLocale(b.items);

      return {
        ...b,
        items: formattedItems,
      };
    }),
  );

/**
 * The `overwriteArrayMerge` function merges two arrays of objects, prioritizing the values from the
 * source array and overwriting any matching objects in the destination array.
 * @param destinationArray - An array of objects with the following properties:
 * @param sourceArray - The `sourceArray` parameter is an array of objects. Each object has the
 * following properties:
 * @returns The function `overwriteArrayMerge` returns an array.
 */
export const overwriteArrayMerge = (
  destinationArray: Array<{
    id: string;
    type?: string;
    children?: Array<{ text: string }>;
  } | null>,
  sourceArray: Array<{
    id: string;
    type?: string;
    children?: Array<{ text: string }>;
  }>,
) => {
  // This is a special case where the `destinationArray` is empty and we want to prioritize the values from the `sourceArray`.
  if (destinationArray.length === 0 && sourceArray.length > 0) {
    return sourceArray;
  }

  // if the destinationArray has a paragraph with a dash, then we want to prioritize the values from the sourceArray for localization
  if (
    destinationArray.find(
      (dEntity) =>
        dEntity?.type?.toLocaleLowerCase() === 'paragraph' &&
        dEntity?.children?.find((child) => child?.text === '-'),
    ) &&
    sourceArray.length > 0
  ) {
    return sourceArray;
  }

  let updatedDestinationArray = destinationArray;

  // This is a special case where the `destinationArray` is shorter than the `sourceArray` and in this case we want to prioritize the values from the `sourceArray`.
  if (sourceArray.length > updatedDestinationArray.length) {
    const difference = sourceArray.length - updatedDestinationArray.length;
    updatedDestinationArray = [
      ...updatedDestinationArray,
      ...new Array(difference).fill(null),
    ];
  }

  // This is the default case where we want to prioritize the values from the `sourceArray` and if they are an object merge them with the `destinationArray`.
  return updatedDestinationArray
    .map(
      (
        dEntity: Partial<{
          __typename: 'Accordion';
          id?: string;
          text?: string;
          type?: string;
        }> | null,
        index,
      ): any => {
        // if the entity is null, then return the entity from the sourceArray as it has a localized value prioritized over the default value from the logic above
        if (dEntity === null) {
          return sourceArray[index];
        }

        // find the matching entity in the sourceArray that is localized
        const foundLocalizedEntity = sourceArray.find(
          (
            sEntity: Partial<{
              __typename: 'Accordion';
              id?: string;
              text?: string;
            }>,
          ) => {
            if (sEntity?.id && dEntity?.id) {
              return sEntity.id === dEntity.id;
            }

            return false;
          },
        );

        // if the entity is found in the sourceArray, then merge the two entities prioritizing the values from the sourceArray
        // remove null properties from the destination entity and the source entity before merging them together to avoid null values being added to the entity
        if (foundLocalizedEntity) {
          return merge(
            removeNullProperties(dEntity) as Partial<{
              __typename: 'Accordion';
              id: string;
            }>,
            removeNullProperties(foundLocalizedEntity) as Partial<{
              __typename: 'Accordion';
              id: string;
            }>,
            {
              arrayMerge: overwriteArrayMerge,
            },
          );
        }

        // if the entity is found in sourceArray with the same index, then return the entity from the sourceArray
        // this is a special case where the entities might not have an ID because they are not an object but an array like the children of a paragraph
        if (sourceArray[index]) {
          return sourceArray[index];
        }

        // if the entity is a paragraph return null and filter it out of the array. As we don't want none translated paragraphs to be rendered
        if (dEntity.type === 'paragraph') {
          return null;
        }

        // if the entity is not found in the sourceArray, then return null so nothing is added to the destinationArray
        return dEntity;
      },
    )
    .filter(Boolean);
};
