import { URL_ACCOUNT_REGISTRATION } from '@seek/adv-constants';
import queryString from 'query-string';

/* signUpUser()
 * Used by the solution to redirect users to SEEK's hosted register page (owned by SSO team).
 *
 * // src/views/shared/forms/EmailCaptureFormContainer/EmailCaptureFormContainer
 * import { signUpUser } from 'src/modules/AuthHandler';
 * ...
 * <Button onClick={signUpUser}>Sign in</Button>
 */
export const signUpUser = (params = {}) => {
  let search = queryString.stringify(params);
  if (search.length > 0) {
    search = `?${search}`;
  }
  const languageCodeMatch = window.location.pathname.match(/(\/th|\/id)/);
  window.location.assign(
    `${
      languageCodeMatch ? languageCodeMatch[0] : ''
    }${URL_ACCOUNT_REGISTRATION}${search}`,
  );
};
