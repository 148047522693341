import type {
  ActionGroupFragment,
  BannerInlineFragment,
  ButtonLinkFragment,
  CButtonLinkSize,
  CButtonLinkTone,
  CButtonVariant,
} from '@seek/cmsu-cms-connect';
import {
  type BlockItemRenderers,
  type BannerRenderers,
  blockRenderers,
  bannerRenderers,
  ActionRenderer,
  Heading,
  Asset,
  Paragraph,
  toUrl,
} from '@seek/cmsu-components';
import { ButtonLink } from '@seek/cmsu-components/src/modules/ActionGroup/ButtonLink';
import { ImageLink } from '@seek/cmsu-components/src/modules/ActionGroup/ImageLink';
import { TextLink } from '@seek/cmsu-components/src/modules/ActionGroup/TextLink';
import { AssetType } from '@seek/cmsu-components/src/modules/Asset/Asset';
import { Box, Column, Columns, Inline, Stack } from 'braid-design-system';
import { Helmet } from 'react-helmet';

import { useConfig } from 'src/hooks/context';

import { HeroBanner } from './HomePage/components/HeroBanner/HeroBanner';

export const blockItemRenderers: BlockItemRenderers = {
  ...blockRenderers.hirer,
  // For context on why we are having to do the following see this thread: https://seekchat.slack.com/archives/C041WNVA1SS/p1687932855347629

  // ALERT: These are custom versions of cmsu-components' components
  // If the schema/props change in HyGraph we need to update these renderers
  // At some point it'd be nice to revisit this and see if we can avoid this duplication
  CBanner: ({ banner_items }) => {
    const { language } = useConfig();
    const { actionGroup, heading, paragraph, reverseContent, image } =
      banner_items as Pick<
        BannerInlineFragment,
        'reverseContent' | 'image' | 'heading' | 'paragraph' | 'actionGroup'
      >;

    const { actions, inline } = actionGroup as Pick<
      ActionGroupFragment,
      'actions' | 'inline'
    > & {
      size?: string;
    };

    const filtered = actions.filter(
      (action) =>
        !(
          action.link?.to?.__typename === 'ExternalUrl' &&
          action.link.to.externalUrl === '-'
        ),
    );

    if (filtered.length === 0) {
      return null;
    }

    return (
      <Box data-testid="inlineBannerTestId">
        <Columns
          space={{ mobile: 'gutter', tablet: 'xlarge' }}
          alignY="center"
          collapseBelow="tablet"
          reverse={reverseContent === 'desktop' || false}
        >
          <Column>
            {image && (
              <Asset
                assetType={AssetType.INLINE_BANNER}
                {...image}
                style={{ maxHeight: '300px' }}
              />
            )}
          </Column>
          <Column>
            <Stack space="gutter">
              {heading && <Heading {...heading} />}
              {paragraph && (
                <Paragraph
                  content={paragraph.Paragraph_text?.raw}
                  {...paragraph}
                />
              )}
              {actionGroup && (
                <Inline
                  space="gutter"
                  alignY="center"
                  collapseBelow="tablet"
                  align={inline?.align || 'left'}
                >
                  {filtered.map(
                    (action) =>
                      action.__typename && (
                        <ActionRenderer
                          key={action.id}
                          action={action as Required<typeof action>}
                          renderers={{
                            CButtonLink: (props: ButtonLinkFragment) => (
                              <ButtonLink
                                {...props}
                                size={props.size as CButtonLinkSize}
                                tone={props.tone as CButtonLinkTone}
                                variant={props.variant as CButtonVariant}
                                link={toUrl(props.link?.to, language)}
                                uniqueTrackingId={props.id}
                              />
                            ),
                            CImageLink: (props) => <ImageLink {...props} />,
                            CTextLink: (props) => <TextLink {...props} />,
                          }}
                        />
                      ),
                  )}
                </Inline>
              )}
            </Stack>
          </Column>
        </Columns>
      </Box>
    );
  },
  CActionGroup: ({ actions, inline }) => {
    const { language } = useConfig();
    const filtered = actions.filter(
      (action) =>
        !(
          action.link?.to?.__typename === 'ExternalUrl' &&
          action.link.to.externalUrl === '-'
        ),
    );

    if (filtered.length === 0) {
      return null;
    }

    return (
      <Inline
        space="gutter"
        alignY="center"
        collapseBelow="tablet"
        align={inline?.align || 'left'}
      >
        {filtered.map(
          (action) =>
            action.__typename && (
              <ActionRenderer
                key={action.id}
                action={action as Required<typeof action>}
                renderers={{
                  CButtonLink: (props) => (
                    <ButtonLink
                      {...props}
                      size={props.size as CButtonLinkSize}
                      tone={props.tone as CButtonLinkTone}
                      variant={props.variant as CButtonVariant}
                      link={toUrl(props.link?.to, language)}
                      uniqueTrackingId={props.id}
                    />
                  ),
                  CImageLink: (props) => <ImageLink {...props} />,
                  CTextLink: (props) => <TextLink {...props} />,
                }}
              />
            ),
        )}
      </Inline>
    );
  },
};

export const customBannerRenderers: BannerRenderers = {
  ...bannerRenderers.universal,
  CBannerShopfront: (banner) => (
    <>
      <Helmet
        link={[{ rel: 'preload', as: 'image', href: banner.image.url }]}
      />
      <HeroBanner {...banner} footnotes={banner.BannerShopfront_footnotes} />
    </>
  ),
};
