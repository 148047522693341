
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t%2BC5iOC4reC4nOC4ueC5ieC5g%2BC4iuC5ieC4leC5ieC4reC4h%2BC4oeC4teC4iOC4s%2BC4meC4p%2BC4meC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIciLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiLguYDguJrguK3guKPguYzguYLguJfguKPguKjguLHguJ7guJfguYzguKLguLLguKfguYDguIHguLTguJnguYTguJsiLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoi4LmA4Lia4Lit4Lij4LmM4LmC4LiX4Lij4Lio4Lix4Lie4LiX4LmM4Liq4Lix4LmJ4LiZ4LmA4LiB4Li04LiZ4LmE4LibIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t%2BC5iOC4reC4nOC4ueC5ieC5g%2BC4iuC5ieC4leC5ieC4reC4h%2BC4oeC4teC4iOC4s%2BC4meC4p%2BC4meC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIciLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiLguYDguJrguK3guKPguYzguYLguJfguKPguKjguLHguJ7guJfguYzguKLguLLguKfguYDguIHguLTguJnguYTguJsiLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoi4LmA4Lia4Lit4Lij4LmM4LmC4LiX4Lij4Lio4Lix4Lie4LiX4LmM4Liq4Lix4LmJ4LiZ4LmA4LiB4Li04LiZ4LmE4LibIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIiwidG9vLWxvbmctcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBhbmphbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBlbmRlayJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIiwidG9vLWxvbmctcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBhbmphbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBlbmRlayJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t%2BC5iOC4reC4nOC4ueC5ieC5g%2BC4iuC5ieC4leC5ieC4reC4h%2BC4oeC4teC4iOC4s%2BC4meC4p%2BC4meC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIciLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiLguYDguJrguK3guKPguYzguYLguJfguKPguKjguLHguJ7guJfguYzguKLguLLguKfguYDguIHguLTguJnguYTguJsiLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoi4LmA4Lia4Lit4Lij4LmM4LmC4LiX4Lij4Lio4Lix4Lie4LiX4LmM4Liq4Lix4LmJ4LiZ4LmA4LiB4Li04LiZ4LmE4LibIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t%2BC5iOC4reC4nOC4ueC5ieC5g%2BC4iuC5ieC4leC5ieC4reC4h%2BC4oeC4teC4iOC4s%2BC4meC4p%2BC4meC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIciLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiLguYDguJrguK3guKPguYzguYLguJfguKPguKjguLHguJ7guJfguYzguKLguLLguKfguYDguIHguLTguJnguYTguJsiLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoi4LmA4Lia4Lit4Lij4LmM4LmC4LiX4Lij4Lio4Lix4Lie4LiX4LmM4Liq4Lix4LmJ4LiZ4LmA4LiB4Li04LiZ4LmE4LibIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIiwidG9vLWxvbmctcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBhbmphbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBlbmRlayJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIiwidG9vLWxvbmctcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBhbmphbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiTm9tb3IgdGVsZXBvbiB0ZXJsYWx1IHBlbmRlayJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UiLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJQaG9uZSBudW1iZXIgaXMgdG9vIGxvbmciLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiUGhvbmUgbnVtYmVyIGlzIHRvbyBzaG9ydCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiW8OP4LiB4Li14LmJ4bm9xIPEg8SDxprDrMOsw6zGjCDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhLiDhua7huKnhur3hur3hur0gx5rHmseaxaHhur3hur3hur3FmSDGpcO2w7bDtsWZ4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIG3Mgseax5rHmsWh4bmvIMOf4bq94bq94bq9IMaS4bq94bq94bq9xbXhur3hur3hur3FmSDhua%2FhuKnEg8SDxIPguIHguLXguYkgNjUgw6fhuKnEg8SDxIPFmcSDxIPEg8On4bmv4bq94bq94bq9xZnFoS5dIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJbe2ZpZWxkTGFiZWx9IMOnxIPEg8SD4LiB4Li14LmJ4LiB4Li14LmJw7bDtsO24bmvIMWh4bmvxIPEg8SDxZnhua8gxbXDrMOsw6zhua%2FhuKkgxIPEg8SDIMWhxqXEg8SDxIPDp%2BG6veG6veG6vV0iLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJbxqThuKnDtsO2w7bguIHguLXguYnhur3hur3hur0g4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSDDrMOsw6zFoSDhua%2FDtsO2w7bDtsO2w7YgxprDtsO2w7bguIHguLXguYnEo10iLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiW8ak4bipw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IOC4geC4teC5iceax5rHmm3MgsOf4bq94bq94bq9xZkgw6zDrMOsxaEg4bmvw7bDtsO2w7bDtsO2IMWh4bipw7bDtsO2xZnhua9dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiW8OP4LiB4Li14LmJ4bm9xIPEg8SDxprDrMOsw6zGjCDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhLiDhua7huKnhur3hur3hur0gx5rHmseaxaHhur3hur3hur3FmSDGpcO2w7bDtsWZ4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIG3Mgseax5rHmsWh4bmvIMOf4bq94bq94bq9IMaS4bq94bq94bq9xbXhur3hur3hur3FmSDhua%2FhuKnEg8SDxIPguIHguLXguYkgNjUgw6fhuKnEg8SDxIPFmcSDxIPEg8On4bmv4bq94bq94bq9xZnFoS5dIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJbe2ZpZWxkTGFiZWx9IMOnxIPEg8SD4LiB4Li14LmJ4LiB4Li14LmJw7bDtsO24bmvIMWh4bmvxIPEg8SDxZnhua8gxbXDrMOsw6zhua%2FhuKkgxIPEg8SDIMWhxqXEg8SDxIPDp%2BG6veG6veG6vV0iLCJ0b28tbG9uZy1waG9uZS1udW1iZXItZXJyb3IiOiJbxqThuKnDtsO2w7bguIHguLXguYnhur3hur3hur0g4LiB4Li14LmJx5rHmseabcyCw5%2Fhur3hur3hur3FmSDDrMOsw6zFoSDhua%2FDtsO2w7bDtsO2w7YgxprDtsO2w7bguIHguLXguYnEo10iLCJ0b28tc2hvcnQtcGhvbmUtbnVtYmVyLWVycm9yIjoiW8ak4bipw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IOC4geC4teC5iceax5rHmm3MgsOf4bq94bq94bq9xZkgw6zDrMOsxaEg4bmvw7bDtsO2w7bDtsO2IMWh4bipw7bDtsO2xZnhua9dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    