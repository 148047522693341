
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoi4LiE4Li44LiT4Lih4Li1IHtjb3VudCwgcGx1cmFsLCA9MSB7MSDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguJfguLXguYjguKPguK3guJTguLPguYDguJnguLTguJnguIHguLLguKN9IG90aGVyIHt7Y291bnR9IOC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5gOC4nuC4t%2BC5iOC4reC5gOC4m%2BC4tOC4lOC4geC4suC4o%2BC5g%2BC4iuC5ieC4h%2BC4suC4mX19IDxMaW5rPuC4lOC4ueC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4ozwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoi4LiE4Li44LiT4Lih4Li1IHtjb3VudCwgcGx1cmFsLCA9MSB7MSDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguJfguLXguYjguKPguK3guJTguLPguYDguJnguLTguJnguIHguLLguKN9IG90aGVyIHt7Y291bnR9IOC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5gOC4nuC4t%2BC5iOC4reC5gOC4m%2BC4tOC4lOC4geC4suC4o%2BC5g%2BC4iuC5ieC4h%2BC4suC4mX19IDxMaW5rPuC4lOC4ueC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4ozwvTGluaz4ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiQW5kYSBtZW1pbGlraSB7Y291bnQsIHBsdXJhbCwgPTEgezEgYWt1biB0ZXJ0dW5kYX0gb3RoZXIge3tjb3VudH0gYWt1biB0ZXJ0dW5kYSB5YW5nIHBlcmx1IGRpYWt0aWZrYW59fS4gPExpbms%2BTGloYXQgYWt1biB0ZXJ0dW5kYTwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiQW5kYSBtZW1pbGlraSB7Y291bnQsIHBsdXJhbCwgPTEgezEgYWt1biB0ZXJ0dW5kYX0gb3RoZXIge3tjb3VudH0gYWt1biB0ZXJ0dW5kYSB5YW5nIHBlcmx1IGRpYWt0aWZrYW59fS4gPExpbms%2BTGloYXQgYWt1biB0ZXJ0dW5kYTwvTGluaz4ifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoi4LiE4Li44LiT4Lih4Li1IHtjb3VudCwgcGx1cmFsLCA9MSB7MSDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguJfguLXguYjguKPguK3guJTguLPguYDguJnguLTguJnguIHguLLguKN9IG90aGVyIHt7Y291bnR9IOC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5gOC4nuC4t%2BC5iOC4reC5gOC4m%2BC4tOC4lOC4geC4suC4o%2BC5g%2BC4iuC5ieC4h%2BC4suC4mX19IDxMaW5rPuC4lOC4ueC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4ozwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoi4LiE4Li44LiT4Lih4Li1IHtjb3VudCwgcGx1cmFsLCA9MSB7MSDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguJfguLXguYjguKPguK3guJTguLPguYDguJnguLTguJnguIHguLLguKN9IG90aGVyIHt7Y291bnR9IOC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5gOC4nuC4t%2BC5iOC4reC5gOC4m%2BC4tOC4lOC4geC4suC4o%2BC5g%2BC4iuC5ieC4h%2BC4suC4mX19IDxMaW5rPuC4lOC4ueC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g%2BC4iuC5ieC4l%2BC4teC5iOC4o%2BC4reC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4ozwvTGluaz4ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiQW5kYSBtZW1pbGlraSB7Y291bnQsIHBsdXJhbCwgPTEgezEgYWt1biB0ZXJ0dW5kYX0gb3RoZXIge3tjb3VudH0gYWt1biB0ZXJ0dW5kYSB5YW5nIHBlcmx1IGRpYWt0aWZrYW59fS4gPExpbms%2BTGloYXQgYWt1biB0ZXJ0dW5kYTwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiQW5kYSBtZW1pbGlraSB7Y291bnQsIHBsdXJhbCwgPTEgezEgYWt1biB0ZXJ0dW5kYX0gb3RoZXIge3tjb3VudH0gYWt1biB0ZXJ0dW5kYSB5YW5nIHBlcmx1IGRpYWt0aWZrYW59fS4gPExpbms%2BTGloYXQgYWt1biB0ZXJ0dW5kYTwvTGluaz4ifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiWW91IGhhdmUge2NvdW50LCBwbHVyYWwsID0xIHsxIHBlbmRpbmcgYWNjb3VudH0gb3RoZXIge3tjb3VudH0gcGVuZGluZyBhY2NvdW50cyB0aGF0IG5lZWQgdG8gYmUgYWN0aXZhdGVkfX0uIDxMaW5rPlZpZXcgcGVuZGluZyBhY2NvdW50czwvTGluaz4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiW8Odw7bDtseax5og4bipxIPEg%2BG5veG6veG6vSB7Y291bnQscGx1cmFsLD0xezEgxqXhur3hur3hur3guIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua99IG90aGVye3tjb3VudH0gxqXhur3hur3hur3guIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoSDhua%2FhuKnEg8SDxIPhua8g4LiB4Li14LmJ4bq94bq94bq94bq94bq94bq9xowg4bmvw7bDtsO2IMOf4bq94bq94bq9IMSDxIPEg8On4bmvw6zDrMOs4bm9xIPEg8SD4bmv4bq94bq94bq9xox9fS4gPExpbms%2B4bm8w6zDrMOs4bq94bq94bq9xbUgxqXhur3hur3hur3guIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoTwvTGluaz5dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UgaGF2ZSBwZW5kaW5nIGFjY291bnRzIjoiW8Odw7bDtseax5og4bipxIPEg%2BG5veG6veG6vSB7Y291bnQscGx1cmFsLD0xezEgxqXhur3hur3hur3guIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua99IG90aGVye3tjb3VudH0gxqXhur3hur3hur3guIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoSDhua%2FhuKnEg8SDxIPhua8g4LiB4Li14LmJ4bq94bq94bq94bq94bq94bq9xowg4bmvw7bDtsO2IMOf4bq94bq94bq9IMSDxIPEg8On4bmvw6zDrMOs4bm9xIPEg8SD4bmv4bq94bq94bq9xox9fS4gPExpbms%2B4bm8w6zDrMOs4bq94bq94bq9xbUgxqXhur3hur3hur3guIHguLXguYnGjMOsw6zDrOC4geC4teC5icSjIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFoTwvTGluaz5dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    