
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoi4LmA4Lib4Li04LiU4Lil4Li04LiH4LiB4LmM4LiZ4Li14LmJ4LmE4Lih4LmI4LmE4LiU4LmJ4LmD4LiK4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIllvdXIgZW1haWwgYWRkcmVzcyBpcyB2ZXJpZmllZCI6IuC4ouC4t%2BC4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC4guC4reC4h%2BC4hOC4uOC4k%2BC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoi4LmA4Lib4Li04LiU4Lil4Li04LiH4LiB4LmM4LiZ4Li14LmJ4LmE4Lih4LmI4LmE4LiU4LmJ4LmD4LiK4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIllvdXIgZW1haWwgYWRkcmVzcyBpcyB2ZXJpZmllZCI6IuC4ouC4t%2BC4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC4guC4reC4h%2BC4hOC4uOC4k%2BC5geC4peC5ieC4pyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJMaW5rIGluaSB0ZWxhaCBrZWRhbHV3YXJzYSIsIlRoaXMgbGluayBpc250IHdvcmtpbmciOiJMaW5rIHRpZGFrIGJlcmZ1bmdzaT8iLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJBbGFtYXQgZW1haWwgQW5kYSB0ZXJ2ZXJpZmlrYXNpIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJMaW5rIGluaSB0ZWxhaCBrZWRhbHV3YXJzYSIsIlRoaXMgbGluayBpc250IHdvcmtpbmciOiJMaW5rIHRpZGFrIGJlcmZ1bmdzaT8iLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJBbGFtYXQgZW1haWwgQW5kYSB0ZXJ2ZXJpZmlrYXNpIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoi4LmA4Lib4Li04LiU4Lil4Li04LiH4LiB4LmM4LiZ4Li14LmJ4LmE4Lih4LmI4LmE4LiU4LmJ4LmD4LiK4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIllvdXIgZW1haWwgYWRkcmVzcyBpcyB2ZXJpZmllZCI6IuC4ouC4t%2BC4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC4guC4reC4h%2BC4hOC4uOC4k%2BC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiLguKXguLTguIfguIHguYzguJnguLXguYnguKvguKHguJTguK3guLLguKLguLjguYHguKXguYnguKciLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoi4LmA4Lib4Li04LiU4Lil4Li04LiH4LiB4LmM4LiZ4Li14LmJ4LmE4Lih4LmI4LmE4LiU4LmJ4LmD4LiK4LmI4Lir4Lij4Li34Lit4LmE4Lih4LmIPyIsIllvdXIgZW1haWwgYWRkcmVzcyBpcyB2ZXJpZmllZCI6IuC4ouC4t%2BC4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC4guC4reC4h%2BC4hOC4uOC4k%2BC5geC4peC5ieC4pyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJMaW5rIGluaSB0ZWxhaCBrZWRhbHV3YXJzYSIsIlRoaXMgbGluayBpc250IHdvcmtpbmciOiJMaW5rIHRpZGFrIGJlcmZ1bmdzaT8iLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJBbGFtYXQgZW1haWwgQW5kYSB0ZXJ2ZXJpZmlrYXNpIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJMaW5rIGluaSB0ZWxhaCBrZWRhbHV3YXJzYSIsIlRoaXMgbGluayBpc250IHdvcmtpbmciOiJMaW5rIHRpZGFrIGJlcmZ1bmdzaT8iLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJBbGFtYXQgZW1haWwgQW5kYSB0ZXJ2ZXJpZmlrYXNpIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiLCJUaGlzIGxpbmsgaXNudCB3b3JraW5nIjoiVGhpcyBsaW5rIGlzbid0IHdvcmtpbmciLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJb4bmu4bipw6zDrMOsxaEgxprDrMOsw6zguIHguLXguYnEtyDhuKnEg8SDxIPFoSDhur3hur3hur3qrZXGpcOsw6zDrMWZ4bq94bq94bq9xoxdIiwiVGhpcyBsaW5rIGlzbnQgd29ya2luZyI6Ilvhua7huKnDrMOsw6zFoSDGmsOsw6zDrOC4geC4teC5icS3IMOsw6zDrMWh4LiB4Li14LmJJ%2BG5ryDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEo10iLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJbw53DtsO2x5rHmsWZIOG6veG6vW3MgsSDxIPDrMOsxpogxIPEg8aMxozFmeG6veG6vcWhxaEgw6zDrMWhIOG5veG6veG6vcWZw6zDrMaSw6zDrOG6veG6vcaMXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGxpbmsgaGFzIGV4cGlyZWQiOiJb4bmu4bipw6zDrMOsxaEgxprDrMOsw6zguIHguLXguYnEtyDhuKnEg8SDxIPFoSDhur3hur3hur3qrZXGpcOsw6zDrMWZ4bq94bq94bq9xoxdIiwiVGhpcyBsaW5rIGlzbnQgd29ya2luZyI6Ilvhua7huKnDrMOsw6zFoSDGmsOsw6zDrOC4geC4teC5icS3IMOsw6zDrMWh4LiB4Li14LmJJ%2BG5ryDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEo10iLCJZb3VyIGVtYWlsIGFkZHJlc3MgaXMgdmVyaWZpZWQiOiJbw53DtsO2x5rHmsWZIOG6veG6vW3MgsSDxIPDrMOsxpogxIPEg8aMxozFmeG6veG6vcWhxaEgw6zDrMWhIOG5veG6veG6vcWZw6zDrMaSw6zDrOG6veG6vcaMXSJ9!"
        )
      )
      });
  
      export { translations as default };
    