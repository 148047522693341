import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  type GetPagesQuery,
  type GetPagesWithEntitiesQueryVariables,
  getPageWithEntities,
  type BannerShopfrontFragment,
} from '@seek/cmsu-cms-connect';
import type { SiteName } from '@seek/melways-sites';

import { formatBlocksForRendering } from './helpers';

type ShopfrontBanner = BannerShopfrontFragment & {
  BannerShopfront_image: BannerShopfrontFragment['image'];
};

export const loadEntityData = async ({
  endpointURL,
  variables,
  previewToken,
  site,
}: {
  endpointURL: string;
  variables: GetPagesWithEntitiesQueryVariables;
  previewToken?: string;
  site: SiteName;
}): Promise<GetPagesQuery['pages'][0]> => {
  const httpLink = createHttpLink({
    uri: endpointURL,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'hyg-stale-if-error': '86400',
      'hyg-stale-while-revalidate': '300',
      ...(previewToken ? { authorization: `Bearer ${previewToken}` } : {}),
    },
  }));

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  const page = await getPageWithEntities({
    client,
    variables,
    formatBlocksForRendering,
    site,
  });

  if (page?.banner?.__typename === 'CBannerShopfront') {
    const banner = page.banner as ShopfrontBanner;
    page.banner.image = banner.BannerShopfront_image;
  }

  return page;
};
