
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiLguJXguKPguKfguIjguKrguK3guJrguK3guLXguYDguKHguKXguKfguYjguLLguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJDaGVjayB5b3VyIHNwYW0gZm9sZGVyIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LmC4Lif4Lil4LmA4LiU4Lit4Lij4LmM4Liq4LmB4Lib4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiRGlkIG5vdCByZWNlaXZlIHRoZSBlbWFpbCB0aXRsZSI6IuC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4reC4teC5gOC4oeC4peC5g%2BC4iuC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iCIsIkZvbGxvdyBlbWFpbCBsaW5rIGluc3RydWN0aW9uIG1lc3NhZ2UiOiLguITguKXguLTguIHguKXguLTguIfguITguYzguYDguJ7guLfguYjguK3guKXguIfguJfguLDguYDguJrguLXguKLguJnguYPguKvguYnguYDguKrguKPguYfguIjguKrguKHguJrguLnguKPguJPguYwg4LiI4Liy4LiB4LiZ4Lix4LmJ4LiZ4LiE4Li44LiT4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lie4Liq4LiV4LmM4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmB4Lij4LiB4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJIiwiUmVnaXN0cmF0aW9uIGNvbXBsZXRlIHBhZ2UgdGl0bGUiOiLguYPguIHguKXguYnguKrguLPguYDguKPguYfguIjguYHguKXguYnguKchIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgbWVzc2FnZSI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4quC5iOC4h%2BC4peC4tOC4h%2BC4geC5jOC4geC4suC4o%2BC5gOC4m%2BC4tOC4lOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4m%2BC4l%2BC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiLguJXguKPguKfguIjguKrguK3guJrguK3guLXguYDguKHguKXguKfguYjguLLguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJDaGVjayB5b3VyIHNwYW0gZm9sZGVyIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LmC4Lif4Lil4LmA4LiU4Lit4Lij4LmM4Liq4LmB4Lib4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiRGlkIG5vdCByZWNlaXZlIHRoZSBlbWFpbCB0aXRsZSI6IuC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4reC4teC5gOC4oeC4peC5g%2BC4iuC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iCIsIkZvbGxvdyBlbWFpbCBsaW5rIGluc3RydWN0aW9uIG1lc3NhZ2UiOiLguITguKXguLTguIHguKXguLTguIfguITguYzguYDguJ7guLfguYjguK3guKXguIfguJfguLDguYDguJrguLXguKLguJnguYPguKvguYnguYDguKrguKPguYfguIjguKrguKHguJrguLnguKPguJPguYwg4LiI4Liy4LiB4LiZ4Lix4LmJ4LiZ4LiE4Li44LiT4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lie4Liq4LiV4LmM4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmB4Lij4LiB4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJIiwiUmVnaXN0cmF0aW9uIGNvbXBsZXRlIHBhZ2UgdGl0bGUiOiLguYPguIHguKXguYnguKrguLPguYDguKPguYfguIjguYHguKXguYnguKchIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgbWVzc2FnZSI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4quC5iOC4h%2BC4peC4tOC4h%2BC4geC5jOC4geC4suC4o%2BC5gOC4m%2BC4tOC4lOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4m%2BC4l%2BC4teC5iCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJQZXJpa3NhIGFwYWthaCBhbGFtYXQgZW1haWwgc3VkYWggYmVuYXIuIiwiQ2hlY2sgeW91ciBzcGFtIGZvbGRlciI6IlBlcmlrc2EgZm9sZGVyIHNwYW0gQW5kYS4iLCJEaWQgbm90IHJlY2VpdmUgdGhlIGVtYWlsIHRpdGxlIjoiVGlkYWsgbWVuZXJpbWEgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IklrdXRpIHRhdXRhbiB1bnR1ayBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLCBzZWhpbmdnYSBBbmRhIGRhcGF0IG1lbWFzYW5nIGlrbGFuIGxvd29uZ2FuIGtlcmphIHBlcnRhbWEgQW5kYS4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkhhbXBpciBzZWxlc2FpISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJQZXJpa3NhIGFwYWthaCBhbGFtYXQgZW1haWwgc3VkYWggYmVuYXIuIiwiQ2hlY2sgeW91ciBzcGFtIGZvbGRlciI6IlBlcmlrc2EgZm9sZGVyIHNwYW0gQW5kYS4iLCJEaWQgbm90IHJlY2VpdmUgdGhlIGVtYWlsIHRpdGxlIjoiVGlkYWsgbWVuZXJpbWEgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IklrdXRpIHRhdXRhbiB1bnR1ayBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLCBzZWhpbmdnYSBBbmRhIGRhcGF0IG1lbWFzYW5nIGlrbGFuIGxvd29uZ2FuIGtlcmphIHBlcnRhbWEgQW5kYS4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkhhbXBpciBzZWxlc2FpISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiLguJXguKPguKfguIjguKrguK3guJrguK3guLXguYDguKHguKXguKfguYjguLLguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJDaGVjayB5b3VyIHNwYW0gZm9sZGVyIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LmC4Lif4Lil4LmA4LiU4Lit4Lij4LmM4Liq4LmB4Lib4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiRGlkIG5vdCByZWNlaXZlIHRoZSBlbWFpbCB0aXRsZSI6IuC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4reC4teC5gOC4oeC4peC5g%2BC4iuC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iCIsIkZvbGxvdyBlbWFpbCBsaW5rIGluc3RydWN0aW9uIG1lc3NhZ2UiOiLguITguKXguLTguIHguKXguLTguIfguITguYzguYDguJ7guLfguYjguK3guKXguIfguJfguLDguYDguJrguLXguKLguJnguYPguKvguYnguYDguKrguKPguYfguIjguKrguKHguJrguLnguKPguJPguYwg4LiI4Liy4LiB4LiZ4Lix4LmJ4LiZ4LiE4Li44LiT4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lie4Liq4LiV4LmM4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmB4Lij4LiB4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJIiwiUmVnaXN0cmF0aW9uIGNvbXBsZXRlIHBhZ2UgdGl0bGUiOiLguYPguIHguKXguYnguKrguLPguYDguKPguYfguIjguYHguKXguYnguKchIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgbWVzc2FnZSI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4quC5iOC4h%2BC4peC4tOC4h%2BC4geC5jOC4geC4suC4o%2BC5gOC4m%2BC4tOC4lOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4m%2BC4l%2BC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiLguJXguKPguKfguIjguKrguK3guJrguK3guLXguYDguKHguKXguKfguYjguLLguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJDaGVjayB5b3VyIHNwYW0gZm9sZGVyIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LmC4Lif4Lil4LmA4LiU4Lit4Lij4LmM4Liq4LmB4Lib4Lih4LiC4Lit4LiH4LiE4Li44LiTIiwiRGlkIG5vdCByZWNlaXZlIHRoZSBlbWFpbCB0aXRsZSI6IuC5hOC4oeC5iOC5hOC4lOC5ieC4o%2BC4seC4muC4reC4teC5gOC4oeC4peC5g%2BC4iuC5iOC4q%2BC4o%2BC4t%2BC4reC5hOC4oeC5iCIsIkZvbGxvdyBlbWFpbCBsaW5rIGluc3RydWN0aW9uIG1lc3NhZ2UiOiLguITguKXguLTguIHguKXguLTguIfguITguYzguYDguJ7guLfguYjguK3guKXguIfguJfguLDguYDguJrguLXguKLguJnguYPguKvguYnguYDguKrguKPguYfguIjguKrguKHguJrguLnguKPguJPguYwg4LiI4Liy4LiB4LiZ4Lix4LmJ4LiZ4LiE4Li44LiT4Liq4Liy4Lih4Liy4Lij4LiW4LmC4Lie4Liq4LiV4LmM4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LmB4Lij4LiB4LiC4Lit4LiH4LiE4Li44LiT4LmE4LiU4LmJIiwiUmVnaXN0cmF0aW9uIGNvbXBsZXRlIHBhZ2UgdGl0bGUiOiLguYPguIHguKXguYnguKrguLPguYDguKPguYfguIjguYHguKXguYnguKchIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgbWVzc2FnZSI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4quC5iOC4h%2BC4peC4tOC4h%2BC4geC5jOC4geC4suC4o%2BC5gOC4m%2BC4tOC4lOC5g%2BC4iuC5ieC4h%2BC4suC4meC5hOC4m%2BC4l%2BC4teC5iCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJQZXJpa3NhIGFwYWthaCBhbGFtYXQgZW1haWwgc3VkYWggYmVuYXIuIiwiQ2hlY2sgeW91ciBzcGFtIGZvbGRlciI6IlBlcmlrc2EgZm9sZGVyIHNwYW0gQW5kYS4iLCJEaWQgbm90IHJlY2VpdmUgdGhlIGVtYWlsIHRpdGxlIjoiVGlkYWsgbWVuZXJpbWEgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IklrdXRpIHRhdXRhbiB1bnR1ayBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLCBzZWhpbmdnYSBBbmRhIGRhcGF0IG1lbWFzYW5nIGlrbGFuIGxvd29uZ2FuIGtlcmphIHBlcnRhbWEgQW5kYS4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkhhbXBpciBzZWxlc2FpISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJQZXJpa3NhIGFwYWthaCBhbGFtYXQgZW1haWwgc3VkYWggYmVuYXIuIiwiQ2hlY2sgeW91ciBzcGFtIGZvbGRlciI6IlBlcmlrc2EgZm9sZGVyIHNwYW0gQW5kYS4iLCJEaWQgbm90IHJlY2VpdmUgdGhlIGVtYWlsIHRpdGxlIjoiVGlkYWsgbWVuZXJpbWEgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IklrdXRpIHRhdXRhbiB1bnR1ayBtZW55ZWxlc2Fpa2FuIHBlbmRhZnRhcmFuLCBzZWhpbmdnYSBBbmRhIGRhcGF0IG1lbWFzYW5nIGlrbGFuIGxvd29uZ2FuIGtlcmphIHBlcnRhbWEgQW5kYS4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkhhbXBpciBzZWxlc2FpISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJDaGVjayB0aGUgZW1haWwgYWRkcmVzcyBpcyBjb3JyZWN0LiIsIkNoZWNrIHlvdXIgc3BhbSBmb2xkZXIiOiJDaGVjayB5b3VyIHNwYW0gZm9sZGVyLiIsIkRpZCBub3QgcmVjZWl2ZSB0aGUgZW1haWwgdGl0bGUiOiJEaWRuJ3QgcmVjZWl2ZSB0aGUgZW1haWw%2FIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IkZvbGxvdyB0aGUgbGluayB0byBjb21wbGV0ZSByZWdpc3RyYXRpb24sIHNvIHlvdSBjYW4gcG9zdCB5b3VyIGZpcnN0IGpvYiBhZC4iLCJSZWdpc3RyYXRpb24gY29tcGxldGUgcGFnZSB0aXRsZSI6IkFsbW9zdCBkb25lISIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBsaW5rIG1lc3NhZ2UiOiJXZSd2ZSBzZW50IGFuIGFjdGl2YXRpb24gbGluayB0byJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJbw4fhuKnhur3hur3hur3Dp8S3IOG5r%2BG4qeG6veG6veG6vSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhIMOsw6zDrMWhIMOnw7bDtsO2xZnFmeG6veG6veG6vcOn4bmvLl0iLCJDaGVjayB5b3VyIHNwYW0gZm9sZGVyIjoiW8OH4bip4bq94bq94bq9w6fEtyDDvcO9w73DtsO2w7bHmseax5rFmSDFocalxIPEg8SDbcyCIMaSw7bDtsO2xprGjOG6veG6veG6vcWZLl0iLCJEaWQgbm90IHJlY2VpdmUgdGhlIGVtYWlsIHRpdGxlIjoiW%2BG4isOsw6zDrMaM4LiB4Li14LmJJ%2BG5ryDFmeG6veG6veG6vcOn4bq94bq94bq9w6zDrMOs4bm94bq94bq94bq9IOG5r%2BG4qeG6veG6veG6vSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmj9dIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IlvGkcO2w7bDtsaaxprDtsO2w7bFtSDhua%2FhuKnhur3hur3hur0gxprDrMOsw6zguIHguLXguYnEtyDhua%2FDtsO2w7Ygw6fDtsO2w7ZtzILGpcaa4bq94bq94bq94bmv4bq94bq94bq9IMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r8WZxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJLCDFocO2w7bDtiDDvcO9w73DtsO2w7bHmseax5ogw6fEg8SDxIPguIHguLXguYkgxqXDtsO2w7bFoeG5ryDDvcO9w73DtsO2w7bHmseax5rFmSDGksOsw6zDrMWZxaHhua8gxLXDtsO2w7bDnyDEg8SDxIPGjC5dIiwiUmVnaXN0cmF0aW9uIGNvbXBsZXRlIHBhZ2UgdGl0bGUiOiJb4bqsxpptzILDtsO2w7bFoeG5ryDGjMO2w7bDtuC4geC4teC5ieG6veG6veG6vSFdIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgbWVzc2FnZSI6IlvFtOG6veG6veG6vSfhub3hur3hur3hur0gxaHhur3hur3hur3guIHguLXguYnhua8gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8On4bmvw6zDrMOs4bm9xIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOs4LiB4Li14LmJxLcg4bmvw7bDtsO2XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGVjayBlbWFpbCBpcyBjb3JyZWN0IG1lc3NhZ2UiOiJbw4fhuKnhur3hur3hur3Dp8S3IOG5r%2BG4qeG6veG6veG6vSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhIMOsw6zDrMWhIMOnw7bDtsO2xZnFmeG6veG6veG6vcOn4bmvLl0iLCJDaGVjayB5b3VyIHNwYW0gZm9sZGVyIjoiW8OH4bip4bq94bq94bq9w6fEtyDDvcO9w73DtsO2w7bHmseax5rFmSDFocalxIPEg8SDbcyCIMaSw7bDtsO2xprGjOG6veG6veG6vcWZLl0iLCJEaWQgbm90IHJlY2VpdmUgdGhlIGVtYWlsIHRpdGxlIjoiW%2BG4isOsw6zDrMaM4LiB4Li14LmJJ%2BG5ryDFmeG6veG6veG6vcOn4bq94bq94bq9w6zDrMOs4bm94bq94bq94bq9IOG5r%2BG4qeG6veG6veG6vSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmj9dIiwiRm9sbG93IGVtYWlsIGxpbmsgaW5zdHJ1Y3Rpb24gbWVzc2FnZSI6IlvGkcO2w7bDtsaaxprDtsO2w7bFtSDhua%2FhuKnhur3hur3hur0gxprDrMOsw6zguIHguLXguYnEtyDhua%2FDtsO2w7Ygw6fDtsO2w7ZtzILGpcaa4bq94bq94bq94bmv4bq94bq94bq9IMWZ4bq94bq94bq9xKPDrMOsw6zFoeG5r8WZxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJLCDFocO2w7bDtiDDvcO9w73DtsO2w7bHmseax5ogw6fEg8SDxIPguIHguLXguYkgxqXDtsO2w7bFoeG5ryDDvcO9w73DtsO2w7bHmseax5rFmSDGksOsw6zDrMWZxaHhua8gxLXDtsO2w7bDnyDEg8SDxIPGjC5dIiwiUmVnaXN0cmF0aW9uIGNvbXBsZXRlIHBhZ2UgdGl0bGUiOiJb4bqsxpptzILDtsO2w7bFoeG5ryDGjMO2w7bDtuC4geC4teC5ieG6veG6veG6vSFdIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgbWVzc2FnZSI6IlvFtOG6veG6veG6vSfhub3hur3hur3hur0gxaHhur3hur3hur3guIHguLXguYnhua8gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8On4bmvw6zDrMOs4bm9xIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOs4LiB4Li14LmJxLcg4bmvw7bDtsO2XSJ9!"
        )
      )
      });
  
      export { translations as default };
    