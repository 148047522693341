
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiE4Li44LiT4Lie4Lij4LmJ4Lit4Lih4LmB4Lil4LmJ4LinISIsIlBvc3QgYWQgbGluayI6IuC4quC4o%2BC5ieC4suC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZ4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIOC4hOC4uOC4k%2BC4nuC4o%2BC5ieC4reC4oeC4l%2BC4teC5iOC4iOC4sOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5geC4o%2BC4geC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiE4Li44LiT4Lie4Lij4LmJ4Lit4Lih4LmB4Lil4LmJ4LinISIsIlBvc3QgYWQgbGluayI6IuC4quC4o%2BC5ieC4suC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZ4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIOC4hOC4uOC4k%2BC4nuC4o%2BC5ieC4reC4oeC4l%2BC4teC5iOC4iOC4sOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5geC4o%2BC4geC5geC4peC5ieC4pyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiQW5kYSBzdWRhaCBzaWFwISIsIlBvc3QgYWQgbGluayI6IkJ1YXQgaWtsYW4gcGVrZXJqYWFuIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJLYW1pIHRlbGFoIG1lbmdha3RpZmthbiBha3VuIEFuZGEuIEFuZGEgc3VkYWggZGFwYXQgbWVtYXNhbmcgaWtsYW4gbG93b25nYW4ga2VyamEgcGVydGFtYSBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiQW5kYSBzdWRhaCBzaWFwISIsIlBvc3QgYWQgbGluayI6IkJ1YXQgaWtsYW4gcGVrZXJqYWFuIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJLYW1pIHRlbGFoIG1lbmdha3RpZmthbiBha3VuIEFuZGEuIEFuZGEgc3VkYWggZGFwYXQgbWVtYXNhbmcgaWtsYW4gbG93b25nYW4ga2VyamEgcGVydGFtYSBBbmRhLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiE4Li44LiT4Lie4Lij4LmJ4Lit4Lih4LmB4Lil4LmJ4LinISIsIlBvc3QgYWQgbGluayI6IuC4quC4o%2BC5ieC4suC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZ4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIOC4hOC4uOC4k%2BC4nuC4o%2BC5ieC4reC4oeC4l%2BC4teC5iOC4iOC4sOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5geC4o%2BC4geC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiE4Li44LiT4Lie4Lij4LmJ4Lit4Lih4LmB4Lil4LmJ4LinISIsIlBvc3QgYWQgbGluayI6IuC4quC4o%2BC5ieC4suC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LmA4Lib4Li04LiU4LmD4LiK4LmJ4LiH4Liy4LiZ4Lia4Lix4LiN4LiK4Li14LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIOC4hOC4uOC4k%2BC4nuC4o%2BC5ieC4reC4oeC4l%2BC4teC5iOC4iOC4sOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4meC5geC4o%2BC4geC5geC4peC5ieC4pyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiQW5kYSBzdWRhaCBzaWFwISIsIlBvc3QgYWQgbGluayI6IkJ1YXQgaWtsYW4gcGVrZXJqYWFuIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJLYW1pIHRlbGFoIG1lbmdha3RpZmthbiBha3VuIEFuZGEuIEFuZGEgc3VkYWggZGFwYXQgbWVtYXNhbmcgaWtsYW4gbG93b25nYW4ga2VyamEgcGVydGFtYSBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiQW5kYSBzdWRhaCBzaWFwISIsIlBvc3QgYWQgbGluayI6IkJ1YXQgaWtsYW4gcGVrZXJqYWFuIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJLYW1pIHRlbGFoIG1lbmdha3RpZmthbiBha3VuIEFuZGEuIEFuZGEgc3VkYWggZGFwYXQgbWVtYXNhbmcgaWtsYW4gbG93b25nYW4ga2VyamEgcGVydGFtYSBBbmRhLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiWW91J3JlIGFsbCBzZXQhIiwiUG9zdCBhZCBsaW5rIjoiQ3JlYXRlIGEgam9iIGFkIiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiJXZSd2ZSBhY3RpdmF0ZWQgeW91ciBhY2NvdW50LiBZb3UncmUgYWxsIHJlYWR5IHRvIHBvc3QgeW91IGZpcnN0IGpvYiBhZC4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiW8Odw7bDtsO2x5rHmseaJ8WZ4bq94bq94bq9IMSDxIPEg8aaxpogxaHhur3hur3hur3hua8hXSIsIlBvc3QgYWQgbGluayI6IlvDh8WZ4bq94bq9xIPEg%2BG5r%2BG6veG6vSDEg8SDIMS1w7bDtsOfIMSDxIPGjF0iLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlvFtOG6veG6veG6vSfhub3hur3hur3hur0gxIPEg8SDw6fhua%2FDrMOsw6zhub3Eg8SDxIPhua%2Fhur3hur3hur3GjCDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvLiDDncO2w7bDtseax5rHmifFmeG6veG6veG6vSDEg8SDxIPGmsaaIMWZ4bq94bq94bq9xIPEg8SDxozDvcO9w70g4bmvw7bDtsO2IMalw7bDtsO2xaHhua8gw73DvcO9w7bDtsO2x5rHmseaIMaSw6zDrMOsxZnFoeG5ryDEtcO2w7bDtsOfIMSDxIPEg8aMLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiW8Odw7bDtsO2x5rHmseaJ8WZ4bq94bq94bq9IMSDxIPEg8aaxpogxaHhur3hur3hur3hua8hXSIsIlBvc3QgYWQgbGluayI6IlvDh8WZ4bq94bq9xIPEg%2BG5r%2BG6veG6vSDEg8SDIMS1w7bDtsOfIMSDxIPGjF0iLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlvFtOG6veG6veG6vSfhub3hur3hur3hur0gxIPEg8SDw6fhua%2FDrMOsw6zhub3Eg8SDxIPhua%2Fhur3hur3hur3GjCDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvLiDDncO2w7bDtseax5rHmifFmeG6veG6veG6vSDEg8SDxIPGmsaaIMWZ4bq94bq94bq9xIPEg8SDxozDvcO9w70g4bmvw7bDtsO2IMalw7bDtsO2xaHhua8gw73DvcO9w7bDtsO2x5rHmseaIMaSw6zDrMOsxZnFoeG5ryDEtcO2w7bDtsOfIMSDxIPEg8aMLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    