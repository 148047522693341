import {
  Locale as CmsLocale,
  Project,
  Stage,
  type GetPagesQuery,
} from '@seek/cmsu-cms-connect';
import type { Locale } from '@seek/melways-sites';

import { loadEntityData } from 'src/modules/ApiClient/cms/loadEntity';
import type { EmployerSiteName, Environment } from 'src/types';

type GetCmsPageContentProps = {
  environment: Environment;
  site: EmployerSiteName;
  locale: Locale;
  pageSlug: string;
} & (
  | {
      previewToken?: never;
      stage?: Stage.Published;
    }
  | {
      previewToken: string;
      stage?: Stage.Draft;
    }
);

export const getCmsPageContent = async ({
  environment,
  site,
  locale,
  pageSlug,
  previewToken,
  stage = Stage.Published,
}: GetCmsPageContentProps) => {
  const endpointURL = Project.Apac; // use Project.ApacStg; to query Hygraph Staging env
  const locales = [locale.replace('-', '_'), CmsLocale.Default];

  try {
    const variables = {
      where: {
        sites_some: { name: site },
        slug: pageSlug,
      },
      locale: locales as CmsLocale[],
      stage,
    };
    const page = await loadEntityData({ endpointURL, variables, previewToken });
    if (page) {
      return page as GetPagesQuery['pages'][0];
    }
  } catch (errorMessage) {
    // eslint-disable-next-line no-console
    console.warn(errorMessage);
    throw new Error(
      `Hygraph fetch for ${environment} ${site} ${locale} ${pageSlug} error: ${errorMessage}`,
    );
  }
};
